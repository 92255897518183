import React from 'react';
import cx from 'classnames';

import Invertase from '../../themes/invertase';
import Card from '../../themes/invertase/Card';
import Hero from '../../themes/invertase/Hero';
import WaveyLine from '../../themes/invertase/WaveyLine';
import Link from '../../components/Link';
import Container from '../../components/Container';

import projects from '../../../config/projects';

import styles from './styles.module.scss';

const WhatWeDo = () => (
  <Invertase theme={'transparent'}>
    <Hero className={styles.hero} theme={'endless-constellation'}>
      <Container>
        <h1>What We Do</h1>
        <h2>What we do text.</h2>
      </Container>
    </Hero>
    <div className={styles.content}>
      <Container />
    </div>
  </Invertase>
);

export default WhatWeDo;
